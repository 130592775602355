var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import palette from 'google-palette';
import * as $ from 'jquery';
import * as L from 'leaflet';
import closeIcon from './assets/close-icon.svg';
import mapIcon from './assets/map.svg';
import './assets/style.css';
var defaultColor = 'rgb(255, 255, 255)';
// const colorMap = {
//   'CAUK Area': 'rgba(0, 255, 0)',
//   'Central UK Area': 'rgba(255, 255, 0)',
//   'Ireland Area': 'rgb(0, 128, 0)',
//   'London Area': 'rgba(0, 0, 255)',
//   'Scotland Area': 'rgba(0, 0, 64)',
//   'Wales Area': 'rgba(255, 0, 0)',
//   'West Country Area': 'rgb(255, 0, 255)',
// };
var classSuffix = 'ukca';
var mapAdded = false;
var addMap = function (el, googleApiKey, mapboxApiKey) { return __awaiter(void 0, void 0, void 0, function () {
    var geojson, colorMap, _i, _a, feature, colors, i, map, highlightStyle, highlightFeature, resetHighlight, areasLayer, inputEl;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, import('./assets/ukca-area-boundaries-simple.geojson'
                /* webpackChunkName: "map-data" */
                /* webpackMode: "lazy" */ )];
            case 1:
                geojson = _b.sent();
                colorMap = {};
                for (_i = 0, _a = geojson.features; _i < _a.length; _i++) {
                    feature = _a[_i];
                    if (!colorMap[feature.properties.area]) {
                        colorMap[feature.properties.area] = "";
                    }
                }
                colors = palette('cb-Set1', Object.keys(colorMap).length);
                for (i = 0; i < colors.length; i++) {
                    colorMap[Object.keys(colorMap)[i]] = "#" + colors[i];
                }
                map = L.map(el, {
                    crs: L.CRS.EPSG3857,
                    minZoom: 4,
                }).setView([54.093409058179, -4.855957], screen.width > 600 ? 6 : 5);
                L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    maxZoom: 18,
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1,
                    accessToken: mapboxApiKey,
                }).addTo(map);
                highlightStyle = {
                    weight: 2,
                    color: '#3B555C',
                    dashArray: '',
                    fillOpacity: 0.4,
                };
                highlightFeature = function (evt) {
                    var feature = evt.target;
                    feature.setStyle(highlightStyle);
                    if (!L.Browser.ie && !L.Browser.opera) {
                        feature.bringToFront();
                    }
                };
                resetHighlight = function (evt) {
                    L.DomEvent.stop(evt);
                    areasLayer.resetStyle(evt.target);
                };
                areasLayer = L.geoJSON(geojson, {
                    style: function (feature) {
                        return {
                            color: 'rgba(0,0,0,0)',
                            fillColor: feature.properties.area in colorMap ? colorMap[feature.properties.area] : defaultColor,
                            weight: 2,
                            opacity: 0.5,
                            fillOpacity: 0.6,
                            fill: true,
                        };
                    },
                    coordsToLatLng: function (coords) {
                        return L.Projection.SphericalMercator.unproject(new L.Point(coords[0], coords[1]));
                    },
                    onEachFeature: function (feature, layer) {
                        var popupText = "<b>" + feature.properties.area + "</b><br/><a href=\"" + feature.properties.url + "?ukca_redirect\">" + feature.properties.url + "</a>";
                        layer.bindPopup(popupText);
                        layer.on({
                            mouseover: highlightFeature,
                            mouseout: resetHighlight,
                        });
                    },
                }).addTo(map);
                inputEl = document.createElement('input');
                inputEl.id = 'formatted_address';
                inputEl.placeholder = "I'm looking for meetings in...";
                el.appendChild(inputEl);
                $('input#formatted_address')
                    .change(function () {
                    var _this = this;
                    var inputVal = $(this)
                        .val()
                        .trim();
                    $.getJSON('https://maps.googleapis.com/maps/api/geocode/json', {
                        key: googleApiKey,
                        address: inputVal,
                        region: 'uk',
                    }, function (geocoded) {
                        var result = geocoded.results[0];
                        $(_this).val(result.formatted_address);
                        var _a = result.geometry.location, lat = _a.lat, lng = _a.lng;
                        var latlng = L.latLng(lat, lng);
                        var point = map.latLngToContainerPoint(latlng);
                        var mapPos = map.getContainer().getBoundingClientRect();
                        var viewportPoint = L.point(mapPos.left, mapPos.top).add(point);
                        var featureEl = document.elementFromPoint(viewportPoint.x, viewportPoint.y);
                        areasLayer.resetStyle();
                        for (var _i = 0, _b = areasLayer.getLayers(); _i < _b.length; _i++) {
                            var layer = _b[_i];
                            if (layer._path === featureEl) {
                                layer.setStyle(highlightStyle);
                                layer.openPopup(latlng);
                            }
                            else if (layer.isPopupOpen()) {
                                layer.closePopup();
                            }
                        }
                    });
                })
                    .keypress(function (event) {
                    if (event.keyCode === 10 || event.keyCode === 13) {
                        event.preventDefault();
                        $('input#formatted_address').trigger('change');
                    }
                });
                return [2 /*return*/];
        }
    });
}); };
window.UKCA = {
    init: function (googleApiKey, mapboxApiKey) { return __awaiter(void 0, void 0, void 0, function () {
        var mapIconEl, overlay, modal, overflow, hideModal, showModal, closeIconEl, map, text, continueMobile, params;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    mapIconEl = document.createElement('div');
                    mapIconEl.innerHTML = "" + mapIcon;
                    mapIconEl.classList.add(classSuffix + "_map-icon");
                    document.body.appendChild(mapIconEl);
                    overlay = document.createElement('div');
                    overlay.classList.add(classSuffix + "_overlay");
                    modal = document.createElement('div');
                    modal.classList.add(classSuffix + "_modal");
                    overflow = document.body.style.overflow;
                    hideModal = function (delay) {
                        if (delay === void 0) { delay = true; }
                        document.body.style.overflow = overflow;
                        overlay.classList.add('hide');
                        modal.classList.remove('show');
                        modal.classList.add('hide');
                        if (delay) {
                            setTimeout(function () {
                                overlay.style.display = 'none';
                            }, 500);
                        }
                        else {
                            overlay.style.display = 'none';
                        }
                    };
                    showModal = function () {
                        document.body.style.overflow = 'hidden';
                        overlay.style.display = 'block';
                        overlay.classList.remove('hide');
                        modal.classList.remove('hide');
                        modal.classList.add('show');
                    };
                    closeIconEl = document.createElement('div');
                    closeIconEl.innerHTML = "" + closeIcon;
                    closeIconEl.classList.add('close');
                    $(closeIconEl).on('click', function () {
                        window.localStorage.setItem('ukca_closed', '1');
                        hideModal();
                    });
                    $(mapIconEl).on('click', function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    showModal();
                                    if (!!mapAdded) return [3 /*break*/, 2];
                                    return [4 /*yield*/, addMap(map, googleApiKey, mapboxApiKey)];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); });
                    map = document.createElement('div');
                    map.classList.add(classSuffix + "_map");
                    $(overlay).append(modal);
                    $(modal).append(closeIconEl);
                    $(modal).append(map);
                    $(document.body).append(overlay);
                    text = document.createElement('div');
                    text.classList.add(classSuffix + "_trad-6");
                    text.innerHTML = 'In the spirit of Tradition Six, C.A. is not allied with any sect, denomination, politics, organisation or institution.';
                    modal.appendChild(text);
                    continueMobile = document.createElement('button');
                    continueMobile.classList.add(classSuffix + "_continue-button");
                    continueMobile.innerHTML = "Continue to " + window.location.host;
                    modal.appendChild(continueMobile);
                    $(continueMobile).on('click', function () {
                        window.localStorage.setItem('ukca_closed', '1');
                        hideModal();
                    });
                    hideModal(false);
                    params = new URLSearchParams(window.location.search);
                    if (params.has('ukca_redirect')) {
                        window.localStorage.setItem('ukca_chosen', '1');
                        return [2 /*return*/];
                    }
                    if (window.localStorage.getItem('ukca_chosen') || window.localStorage.getItem('ukca_closed')) {
                        return [2 /*return*/];
                    }
                    showModal();
                    return [4 /*yield*/, addMap(map, googleApiKey, mapboxApiKey)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); },
};
